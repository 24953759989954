import { render, staticRenderFns } from "./TitleBlock.vue?vue&type=template&id=06f452bc&scoped=true&"
import script from "./TitleBlock.vue?vue&type=script&lang=js&"
export * from "./TitleBlock.vue?vue&type=script&lang=js&"
import style0 from "./TitleBlock.vue?vue&type=style&index=0&id=06f452bc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f452bc",
  null
  
)

export default component.exports