<template>
  <div class="Introduce" :style="listheight">
    <div class="data" v-for="(item,index) in data" :key="index">
      <div class="avatar">
        <img :src="item.Avatar" v-if="item.Avatar"  alt=""/>
      </div>
      <div class="Info">{{item.Name}}<span>|</span>{{item.Class}}</div>
      <div class="Information">{{item.Brief}}</div>
    </div>
  </div>
</template>


<script>
import {FindCommittee} from "@/api/Object";
import {Notify} from "vant";

export default {
  data() {
    return {
      listheight: '',
      data:[]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let listheight = document.documentElement.clientHeight - 345
      listheight = 'height:' + listheight + 'px'
      this.listheight = listheight
      this.getdata()
    },
    getdata(){
      let ObjectID = this.$route.query.id

      FindCommittee({ObjectID}).then(res=>{
        if (res.errorCode == 200) {
          this.data = res.payload
        } else {
          Notify({type: "danger", message:'服务器出错'});
        }
      })
    }
  }

}
</script>

<style scoped lang="scss">
.Introduce {
  width: 100%;
  overflow-y: auto;
  .data{
    width: 300px;
    height: 150px;
    background-color: #21242e;
    margin: 0 auto;
    border-radius: 15px;
    position: relative;
    margin-bottom: 15px;
    .avatar {
      position: absolute;
      width: 35px;
      height: 35px;
      background-color: #d8d8d8;
      border-radius: 50%;
      left: 15px;
      top: 15px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .Info{
      color: #f9fbff;
      position: absolute;
      left: 70px;
      top: 23px;
      font-size: 15px;
      font-weight: bold;
      span{
        margin-left: 10px;
        margin-right: 10px;
        font-weight: normal;
      }
    }
    .Information{
      position: absolute;
      left: 70px;
      top: 50px;
      width: 215px;
      height: 80px;
     font-size: 13px;
      color: #55595f;
      overflow-y: auto;
    }
  }

}
</style>