<template>
  <div class="Task" :style="listheight">
    <div class="data">
      <div class="Time"></div>
      <div class="Illustrate">
        努力建设中<br>敬请期待
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      listheight: '',

    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let listheight = document.documentElement.clientHeight - 345
      listheight = 'height:' + listheight + 'px'
      this.listheight = listheight
    }
  }

}
</script>

<style scoped lang="scss">
.Task {
  width: 100%;
  overflow-y: auto;
  .data{
    width: 300px;
    height: 150px;
    margin: 0 auto;
    position: relative;
    .Time{
      width: 80px;
      height: 80px;
      background-image: url("../../../assets/image/Time.png");
      background-size: 100% 100%;
      margin: 0 auto;
    }
    .Illustrate{
      font-size: 15px;
      color: #565656;
      text-align: center;
    }
  }

}
</style>