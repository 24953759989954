<template>
  <div class="Report" :style="listheight">
    <div class="data" v-for="(item,index) in data" :key="index">
      <a class="goToUrl" :href="item.ToUrl" target="_blank">
        <div class="cover">
          <img :src="item.ImageUrl" v-if="item.ImageUrl"  alt=""/>
        </div>
        <div class="Title">{{item.Title}}</div>
        <div class="Time">{{item.Time}}</div>
        <div class="Road">{{item.Road}}</div>
      </a>
    </div>
  </div>
</template>


<script>
import {FindNew} from "@/api/Object";
import {Notify} from "vant";

export default {
  data() {
    return {
      listheight: '',
      data:[]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let listheight = document.documentElement.clientHeight - 345
      listheight = 'height:' + listheight + 'px'
      this.listheight = listheight
      this.getdata()
    },
    getdata(){
      let ObjectID = this.$route.query.id
      FindNew({ObjectID}).then(res=>{
        if (res.errorCode == 200) {
          let data=res.payload
          for (let i in data){
            let time=data[i].Time
            let day = new Date(time);
            data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          }
          this.data = res.payload
        } else {
          Notify({type: "danger", message:'服务器出错'});
        }
      })
    }
  }

}
</script>

<style scoped lang="scss">
.Report {
  width: 100%;
  overflow-y: auto;
  .data{
    width: 300px;
    height: 115px;
    background-color: #21242e;
    margin: 0 auto;
    border-radius: 15px;
    position: relative;
    margin-bottom: 15px;
    .cover {
      position: absolute;
      width: 100px;
      height: 90px;
      background-color: #d8d8d8;
      left: 15px;
      top: 15px;
      border-radius: 15px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
      }
    }
    .Title{
      color: #f9fbff;
      position: absolute;
      left: 125px;
      top: 17px;
      font-size: 15px;
      font-weight: bold;
      width: 160px;
    }
    .Time{
      position: absolute;
      left: 125px;
      top: 60px;
      font-size: 13px;
      color: #55595f;
    }
    .Road{
      position: absolute;
      left: 125px;
      top: 85px;
      font-size: 13px;
      color: #55595f;
    }
  }
}

.goToUrl{
  display: block;
  width: 100%;
  height: 100%;
}
</style>