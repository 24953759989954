<template>
  <div class="Object">
    <NavToPush></NavToPush>
    <TitleBlock :ObjectData="ObjectData"></TitleBlock>
    <NavClass :TClass="TClass"></NavClass>
    <Introduce v-if="TClass=='项目简介'"></Introduce>
    <Committee v-if="TClass=='项目委员会'"></Committee>
    <Task v-if="TClass=='贡献任务'"></Task>
    <Report v-if="TClass=='项目报道'"></Report>
    <Feedback v-if="TClass=='留言反馈'"></Feedback>
  </div>
</template>

<script>
import NavToPush from "@/views/Object/Com/NavToPush.vue";
import TitleBlock from '@/views/Object/Com/TitleBlock.vue'
import NavClass from '@/views/Object/Com/NavClass.vue'
import Introduce from '@/views/Object/Com/Introduce.vue'
import Committee from '@/views/Object/Com/Committee.vue'
import Task from '@/views/Object/Com/Task.vue'
import Report from '@/views/Object/Com/Report.vue'
import Feedback from '@/views/Object/Com/Feedback.vue'
import {FindInfo} from "@/api/Object";
import {Notify} from "vant";

export default {
  data() {
    return {
      ObjectData: {},
      TClass: '项目简介',
    }
  },
  components: {NavToPush, TitleBlock, NavClass, Introduce, Committee, Task, Report, Feedback},
  mounted() {
    this.initInfo()
  },
  methods: {
    initInfo() {
      let info = this.$store.state.user.info
      let User_ID = info.id
      let ObjectID = this.$route.query.id

      FindInfo({User_ID,ObjectID}).then(res => {
        if (res.errorCode == 200) {
          let data=res.payload
          this.ObjectData = data;
        } else {
          Notify({type: "danger", message:'服务器出错'});
        }
      })
    },
    switchClass(value) {
      this.TClass = value
    }
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 100%;
  background-color: #15171d;

}
</style>