<template>
  <div class="Feedback" :style="listheight">
    <textarea class="opinion" placeholder="请写下您的意见" v-model="form.Text"/>
    <input class="phone" v-model="form.Phone" placeholder="手机/邮箱/QQ (选填)"/>
    <div class="addImage">
      <div class="icon"></div>
      <div class="text">添加图片</div>
      <img :src="form.ContentImage" alt="" v-if="form.ContentImage"/>
      <van-uploader :after-read="afterRead" class="uploader"/>
    </div>
    <div class="getAdd" @click="send">提交留言</div>
  </div>
</template>

<script>
import {Notify, Uploader} from "vant";
import {AddLeaveMessage} from "@/api/Object";

export default {
  data() {
    return {
      listheight: '',
      form: {Text: '', Phone: '', ContentImage: ''},
    }
  },
  mounted() {
    this.init()
  },
  components: {
    [Uploader.name]: Uploader,
  },
  methods: {
    close(){
      this.form.Text=''
      this.form.Phone=''
      this.form.ContentImage=''
    },
    send() {
      let info = this.$store.state.user.info
      let User_ID = info.id
      let ObjectID = this.$route.query.id
      let form = this.form
      AddLeaveMessage({ObjectID,User_ID,...form}).then(res=>{
        if (res.errorCode == 200) {
          Notify({type: "success", message:res.payload});
          this.close()
        } else {
          Notify({type: "danger", message:res.errorMsg});
        }
      })
    },
    afterRead(file) {
      this.form.ContentImage = file.content
    },
    init() {
      let listheight = document.documentElement.clientHeight - 345
      listheight = 'height:' + listheight + 'px'
      this.listheight = listheight
    }
  },
  beforeDestroy(){
    this.close()
  }
}
</script>

<style scoped lang="scss">
.Feedback {
  width: 80%;
  margin: 0 auto;
  overflow-y: auto;

  .opinion {
    width: 90%;
    height: 150px;
    color: #52535e;
    background-color: #21242e;
    border-radius: 15px;
    padding: 10px;
    border: none;
    outline: none;
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .phone {
    width: 90%;
    height: 30px;
    color: #52535e;
    background-color: #21242e;
    border-radius: 15px;
    padding: 10px;
    border: none;
    outline: none;
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .addImage {
    width: 80px;
    height: 75px;
    border-radius: 13px;
    background-color: #21242e;
    position: relative;
    float: left;

    .icon {
      position: absolute;
      left: 25px;
      top: 10px;
      width: 30px;
      height: 30px;
      background-image: url("../../../assets/image/Image.png");
      background-size: 100% 100%;
    }

    .text {
      text-align: center;
      width: 100%;
      color: #52535e;
      font-size: 14px;
      position: absolute;
      top: 40px;
    }

    .uploader {
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 15px;
    }
  }

  .getAdd {
    width: 180px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: linear-gradient(180deg, #3D75E4 0%, #7C5FD7 100%);
    color: #FFFFFF;
    border-radius: 15px;
    font-weight: bold;
    float: left;
    font-size: 16px;
    margin-left: 20px;
  }
}
</style>