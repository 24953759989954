<template>
  <div class="NavToPush">
    <div class="block" v-bind:class="{blocks:TClass=='项目简介'}"  @click="switchClass('项目简介')">
      项目简介
      <div class="link" v-bind:class="{link_1:TClass=='项目简介'}"></div>
    </div>
    <div class="block" v-bind:class="{blocks:TClass=='项目委员会'}" @click="switchClass('项目委员会')">
      项目委员会
      <div class="link" v-bind:class="{link_1:TClass=='项目委员会'}" ></div>
    </div>
    <div class="block" v-bind:class="{blocks:TClass=='贡献任务'}" @click="switchClass('贡献任务')">
      贡献任务
      <div class="link" v-bind:class="{link_1:TClass=='贡献任务'}" ></div>
    </div>
    <div class="block" v-bind:class="{blocks:TClass=='项目报道'}" @click="switchClass('项目报道')">
      项目报道
      <div class="link" v-bind:class="{link_1:TClass=='项目报道'}" ></div>
    </div>
    <div class="block" v-bind:class="{blocks:TClass=='留言反馈'}"  @click="switchClass('留言反馈')">
      留言反馈
      <div class="link" v-bind:class="{link_1:TClass=='留言反馈'}"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    TClass: {
      type: String
    }
  },
  methods: {
    switchClass(value) {
      if (value == this.TClass) {
        return
      }
      this.$parent.switchClass(value)
    }
  }
}
</script>

<style scoped lang="scss">
.NavToPush {
  width: 90%;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;

  .block {
    width: 20%;
    height: 100%;
    text-align: center;
    position: relative;
    background:#57595d;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: bold;
    font-size: 13px;

    .link {
      width: 100%;
      height: 2px;
      background: #57595d;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .link_1 {
      background: linear-gradient(180deg, #3D75E4 0%, #7C5FD7 100%);
    }
  }

  .blocks{
    background: linear-gradient(180deg, #3D75E4 0%, #7C5FD7 100%);
    -webkit-background-clip: text;
  }
}
</style>