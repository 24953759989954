<template>
  <div class="Introduce" :style="listheight">
    <div class="basic">
<!--      <div class="title">{{ ObjectData.Title }}</div>-->
      <div class="TitleData" v-html="ObjectData.TitleData"></div>
    </div>
    <div class="history">
      <div class="title">发展历程</div>
      <div class="data" v-for="(item,index) in data" :key="index">
        <div class="icon"></div>
        <div class="link"></div>
        <div class="time">{{item.Time}}</div>
        <div class="information">{{item.Text}}</div>
      </div>
    </div>
  </div>
</template>


<script>
import {FindBrief} from "@/api/Object";
import {Notify} from "vant";

export default {
  data() {
    return {
      listheight: '',
      ObjectData: {
        Title: '基本信息',
        TitleData: '基本信息基本信息基本信息基本信息基本信息基本信息基本信息'
      },
      data:[]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let listheight = document.documentElement.clientHeight - 345
      listheight = 'height:' + listheight + 'px'
      this.listheight = listheight

      this.getdata()
    },
    getdata(){
      let ObjectID = this.$route.query.id

      FindBrief({ObjectID}).then(res=>{
        if (res.errorCode == 200) {
          this.data = res.payload.History
          this.ObjectData.TitleData=res.payload.Brief
        } else {
          Notify({type: "danger", message:'服务器出错'});
        }
      })
    }
  }

}
</script>

<style scoped lang="scss">
.Introduce {
  width: 100%;
  overflow-y: auto;

  .basic {
    padding: 20px;
    width: 77%;
    margin: 0 auto;
    background-color: #21242e;
    border-radius: 15px;
    margin-bottom: 10px;

    .title {
      color: #f1f3f5;
      text-indent: 1rem;
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 15px;
    }

    .TitleData {
      font-size: 14px;
      width: 90%;
      margin: 0 auto;
      color: #f1f3f5;
    }
  }

  .history {
    padding: 20px;
    width: 77%;
    margin: 0 auto;
    background-color: #21242e;
    border-radius: 15px;

    .title {
      color: #f1f3f5;
      text-indent: 1rem;
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 15px;
    }

    .data {
      width: 90%;
      height: 50px;
      margin: 0 auto;
      font-size: 14px;
      position: relative;
      .icon{
        background-color: #7C5FD7;
        width: 15px;
        height: 15px;
        border-radius: 50px;
      }
      .link{
        width: 3px;
        height:35px ;
        background-color: #171722;
        position: absolute;
        left: 6px;
        top: 15px;
      }
      .time{
        color: #c8c8d3;
        position: absolute;
        left: 18px;
        top: 0;
        font-weight: bold;
      }
      .information{
        color: #c8c8d3;
        position: absolute;
        left: 18px;
        top: 20px;
      }
    }
  }
}
</style>