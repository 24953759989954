<template>
  <div class="NavToPush">
    <div class="black" @click="black"></div>
  </div>
</template>

<script>
export default {
  methods:{
    black(){
      this.$router.go(-1)
    }
  }
}

</script>

<style scoped lang="scss">
.NavToPush{
  width: 100%;
  height: 60px;
  position: relative;
  .black{
    width: 30px;
    height: 25px;
    background-image: url("../../../assets/image/Arrowhead-1.png");
    background-size: 100% 100%;
    transform: rotate(180deg);
    position: absolute;
    top: 20px;
    left: 10px;
  }
}
</style>