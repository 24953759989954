<template>
  <div class="TitleBlock">
    <div class="TopBlock"></div>
    <div class="bg">
      <img :src="ObjectData.LogoImage" alt="">
      <div class="Interest" v-if="ObjectData.Interest==false" @click="addObject">+ 关注</div>
      <div class="Interest" v-else @click="exit">取消关注</div>
    </div>
    <div class="title">{{ObjectData.Title}}</div>
    <div class="url">{{ObjectData.WebsiteUrl}}</div>
    <div class="number"><div class="icon"></div>{{ObjectData.ToNumber}}</div>
  </div>
</template>

<script>
import {AddInterest, CloseInterest} from "@/api/Object";
import {Dialog, Notify} from "vant";

export default {
  props: {
    ObjectData: {
      type: Object
    }
  },
  methods:{
    addObject(){
      let info = this.$store.state.user.info
      let User_ID = info.id
      let ObjectID = this.$route.query.id

      AddInterest({User_ID,ObjectID}).then(res=>{
        if (res.errorCode == 200) {
          Notify({type: "success", message:res.payload});
          this.$parent.initInfo()
        } else {
          Notify({type: "danger", message:'服务器出错'});
        }
      })
    },
    closeObject(){
      let info = this.$store.state.user.info
      let User_ID = info.id
      let ObjectID = this.$route.query.id

      CloseInterest({User_ID,ObjectID}).then(res=>{
        if (res.errorCode == 200) {
          Notify({type: "success", message:res.payload});
          this.$parent.initInfo()
        } else {
          Notify({type: "danger", message:'服务器出错'});
        }
      })
    },
    exit() {
      Dialog.confirm({
        title: '系统',
        message: '是否确认取消关注该项目吗？'
      }).then(() => {
       this.closeObject()
      }).catch(() => {
        // on cancel
      });
    }
  }
}
</script>

<style scoped lang="scss">
.TitleBlock{
  width: 90%;
  height: 225px;
  margin: 0 auto;
  background: linear-gradient(180deg, #21242e 70%, rgba(255, 255,255, 0.2) 100%);
  border-radius: 10px;
  position: relative;
  margin-bottom: 10px;
  .TopBlock{
    width: 100%;
    height: 20px;
  }
  .bg{
    width: 90%;
    height: 140px;
    margin: 0 auto;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 10px;
    position: relative;
    img{
      width: 100%;
      height: 100%;
    }

    .Interest{
      width: 85px;
      height: 30px;
      background: linear-gradient(180deg, #3D75E4 0%, #7C5FD7 100%);
      color: #FFFFFF;
      text-align: center;
      line-height: 30px;
      font-weight: bold;
      position: absolute;
      left: 10px;
      top: 30px;
      font-size: 13px;
      border-radius: 15px;
    }
  }
  .title{
    width: 100%;
    color: #fdffff;
    font-weight: bold;
    text-indent: 1rem;
    letter-spacing:5px;
    font-size: 16px;
  }
  .url{
    font-size: 14px;
    color: #8c8e94;
    text-indent: 1rem;
    font-weight: bold;
  }
  .number{
    position: absolute;
    right: 20px;
    top: 170px;
    color: #5f54a2;
    font-weight: bold;
    .icon{
      width: 15px;
      height: 15px;
      display: inline-block;
      background-image: url("../../../assets/image/Token.png");
      background-size: 100% 100%;
      margin-right: 5px;
    }
  }
}
</style>